@keyframes animate-banner {
    from {opacity: 0;}
    to {opacity: 1;}
}
@keyframes animate-line {
    from {width: 0%;}
    to {width: 100%;}
}
@keyframes animate-content {
    from {opacity: 0;}
    to {opacity: 1;}
}

.flash {
  font-family: arial;
  text-align: center;
  position:fixed;
  z-index: 66;
  top: 0;
  width: 100%;
  height: 80px;
  background-color: rgba(25, 25, 25, 0.89);
  animation-name: animate-banner;
  animation-duration: 1s;
}

.flash .message-content {
  margin: 20px 0;
  animation-name: animate-content;
  animation-duration: 0.5s;
}

.flash .message-type {
  font-size: 1.5em;
  color: #98c950;
  margin-right: 10px;
  font-weight: bold;
}

.flash .message-body {
  font-size: 1.2em;
  color: #fff;
  animation-name: animate-content;
  animation-duration: 1s;

}

.flash .line {
  position: relative;
  height: 3px;
  background-color: #98c950;
  animation-name: animate-line;
  animation-duration: 1s;
  margin: 0 auto;

}
.flash.success .message-type,
.flash.success .close-flash {
  color: #98c950;
}
.flash.success  .line {
  background-color: #98c950;
}

.flash.error .message-type,
.flash.error .close-flash {
  color: #d9534f;
}
.flash.error  .line {
  background-color: #d9534f;
}

.flash.warning .message-type,
.flash.warning .close-flash {
  color: #ffa500;
}
.flash.warning .line {
  background-color: #ffa500;
}

.flash.information .message-type,
.flash.information .close-flash {
  color: #257ca3;
}
.flash.information .line {
  background-color: #257ca3;
}

.flash.twitter .message-type,
.flash.twitter .close-flash {
  color: #1DA1F2;
}
.flash.twitter .line {
  background-color: #1DA1F2;
}

.twitter-flash-icon {
  color: #1DA1F2;
  font-size: 36px;
}

.sms-flash-icon {
  color: #98c950;
  font-size: 36px;
  margin: 0 5px;
}

.fa.close-flash {
  position: absolute;
  right: 5px;
  top: 3px;
  font-size: 20px;
  cursor: pointer;
}

.loading-flash {
  position: fixed;
  top: 40%;
  left: 36%;
  width: 28%;
  height: 20%;
  outline: none;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 5px 15px rgba(0,0,0,.4);
  z-index: 100;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}