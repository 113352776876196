@import url('https://fonts.googleapis.com/css?family=Lato');

html, body, #root,
.container, .auth-container {
  height: 100%;
  width: 100%;
  font-family: 'Lato', 'Helvetica Neue', Helvetica, sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #333;
}

button, input, optgroup, select, textarea, body {
  font-family: 'Lato', 'Helvetica Neue', Helvetica, sans-serif;
  color: #333;
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

/**** FIREFOX ONLY ****/

@-moz-document url-prefix() {
  html, body, #root, .container, .auth-container,
  button, input, optgroup, select, textarea, body {
    font-family: 'Helvetica Neue', Helvetica, sans-serif;
  }
}

/****            ****/


video {
  width: 100%    !important;
  height: auto   !important;
  outline: none;
}

.ReactModal__Body--open {
  overflow: hidden;
}

a {
  color: inherit;
}

a:focus,
a:active {
  outline: none;
}

a::-moz-focus-inner {
  border: 0;
  outline: none;
}

.link-reset {
  color: inherit;
  text-decoration: none;
}

.full-screen-loader {
  height: 80vh;
  width: 100%;
}

button.btn {
  align-items: center;
}

button.btn:disabled {
  background-color: #bbb;
  cursor: default;
}


/***** column layout *****/

.auth-container {
  /*margin: 0 auto;*/
  display: flex;
  flex-direction: column;
}

.auth-layout {
  display: flex;
  flex-direction: row;
  flex: 1;
  min-height: 0;
}

.column-container {
  display: flex;
  flex: auto;
  overflow-x: auto;
  background-color: #ebece7;
}

.column-container::after {
    content: '\00a0';
    margin: 0 -5px;
}

.column-container > div {
  width: 400px;
  max-width: calc(100vw - 80px);
  display: flex;
  flex: none;
  flex-direction: column;
  /* box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); */
  margin: 10px 10px;
  overflow-y: auto;
  position: relative;
  background-color: #fff;
}

.column-container h3 {
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 5px;
  margin: 0;
}

.column-header {
  position: relative;
  height: 52px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  /* box-shadow: 1px 1px 5px rgba(0,0,0,0.15); */
  flex: none;
  z-index: 1;
  /* border-bottom: 1px solid #e8ece7; */
}

.column-body {
  flex: 1;
  overflow-y: auto;
  /*added to hide the unread bubble on messages/activities*/
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

/***** list items *****/

.list-item-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px 10px 10px;
  border-right: 15px solid transparent;
  min-height: min-content;
  position: relative;
}

.list-item-container:hover {
  background-color: #f6f6f6;
  cursor: pointer;
}

.column-body .list-item-container.list-item-active {
  background-color: #e8ece7;
  border-right: 5px solid #bbb;
}

.list-item-left {
  display: flex;
  align-items: center;
  min-width: 0;
}

.list-item-right {
  display: flex;
  align-items: center;
  flex: none;
  margin-left: 5px;
}

.list-item-body {
  display: flex;
  flex-direction: column;
  min-width: 0;
  margin-left: 10px;
}

.list-item-body > div {
  margin: 3px 0;
}

.list-item-content {
  font-size: 12px;
  color: #595959;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.list-item-title {
  font-size: 18px;
}


/***** avatar *****/

.avatar {
  height: 50px;
  width: 50px;
  border-radius: 30px;
  flex: none;
  object-fit: cover;
  /* margin-right: 10px; */
}

.avatar.small, .avatar-text.small {
  height: 40px;
  width: 40px;
  border-radius: 20px;
}

.avatar-text {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 30px;
  text-align: center;
  background-color: #A0D346;
  color: #000;
  text-transform: uppercase;
  flex: none;
  /* margin-right: 10px; */
  overflow: hidden;
}

.avatar-text.list, .avatar.list {
  border-radius: 5px;
}

.avatar-text.conversation,
.avatar.conversation {
  margin-right: 5px;
}

.avatar-text.conversation.analytics,
.avatar.conversation.analytics {
  margin-left: 5px;
  margin-right: 0;
}

.avatar.user-menu {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  margin-right: 10px;
}

.avatar.activity-overlap {
  height: 30px;
  width: 30px;
  border-radius: 20px;
  margin-right: 10px;
  top: -20px;
  left: 20px;
  position: relative;
}

.avatar-text.activity-overlap {
  height: 30px;
  width: 30px;
  border-radius: 20px;
  margin-right: 10px;
  top: -20px;
  left: 20px;
  position: relative;
}

.from-coach-type.activity {
  height: 0px;
  width: 30px;
  border-radius: 20px;
  margin-right: 10px;
  top: -20px;
  left: 40px;
  position: relative;
}

.from-coach-type.tooltip {
  width: 120px;
  background-color: #53932e;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}

.selected-container .avatar.user-menu {
  height: 34px;
  width: 34px;
  border-radius: 17px;
}

.avatar-text.user-menu {
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 20px;
  margin-right: 10px;
  font-size: 12px;
}

.selected-container .avatar-text.user-menu {
  height: 34px;
  width: 34px;
  line-height: 34px;
  border-radius: 17px;
  font-size: 12px;
}

.avatar-text-large {
  line-height: 90px;
  width: 90px;
  height: 90px;
  border-radius: 45px;
  text-align: center;
  background-color: #53932e;
  color: #fff;
  text-transform: uppercase;
  margin-right: 10px;
  font-size: 24px;
  flex: none;
}

/***** unread bubble *****/
.unread-bubble-small {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffa500;
  height: 12px;
  width: 12px;
  padding: 0px;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  left: 5px;
}

.unread-bubble-small.selected {
  top: -2px;
  left: -3px;
}

/***** recruits not found in search *****/
.contacts-not-found {
  color: #53932e;
  padding: 10px;
  text-align: center;
}

/***** button *****/

.btn {
  display: flex;
  color: #fff;
  background-color: #5cb85c;
  border: none;
  padding: 7px 12px;
  font-size: 12px;
  line-height: normal;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
}

.btn.loading {
  padding: 3px 3px;
  cursor: default;
}

.btn.dot-loader {
  cursor: default;
  height: 28px;
}

.multi-button-container {
  display: flex;
}

.column-input {
  box-sizing: border-box;
  height: 106px;
  width: 100%;
  resize: vertical;
  color: #333;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  display: block;
}

.column-input:focus {
  border-color: #73bd32;
  outline: none;
}

.column-form {
  position: relative;
}

.column-form-container {
  padding:0px;
}

.column-form-bar {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  background-color: #fff;
}

.column-form-icon-group {
  display: flex;
  align-items: center;
}

.column-form-icon-group svg,
.column-form-icon-group i,
.column-form-icon-group .whistleid-logo {
  cursor: pointer;
  margin-right: 15px;
}

.whistleid-logo {
  width: 25px;
  height: auto;
}


/***** date picker *****/

.column-form .react-datepicker {
  font-size: 0.7rem;
}

.message-form-date-picker {
  top: -1px !important;
  left: -42px !important;
  z-index: 2;
}

.message-form-button-container {
  display: flex;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  padding-left: 0;
  padding-right: 0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    padding: 5px 2px;
}

.react-datepicker__day--keyboard-selected {
  border-radius: 0;
  background-color: transparent;
  color: #000;
}

.react-datepicker__day--keyboard-selected:hover {
  background-color: transparent;
}

.modal {
  display: flex;
  position: relative;
  /* border: 1px solid #ddd; */
  overflow: auto;
  outline: none;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 5px 15px rgba(0,0,0,.4);
  max-width: 600px;
  max-height: 95vh;
  margin: 10px
}

.modal.coachboard,
.modal.large {
  max-width: 800px;
  display: flex;
  flex-direction: column;
}

.modal.coachboard-columns {
  max-width: 1000px;
  display: flex;
  flex-direction: column;
}

.modal-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0,0,0, 0.3);
  z-index: 30;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-overlay-two {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0,0,0, 0.3);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-results-container {
  overflow-y: auto;
  flex: auto;
}

.initial-header {
  color: #595959;
  background-color: #caeab5;
  padding: 4px 20px;
}

.contact-search {
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 5px;
  font-size: 16px;
  flex: none;
}

.contact-search-input {
  background-color: #fff;
  color: #333;
  width: 100%;
  margin: 0 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 8px 30px 8px 8px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.contact-search-input:focus {
  border-color: #73bd32;
  outline: none;
}

.contact-search .contact-search-icon {
  position: absolute;
  right: 20px;
  top: auto;
  color: #595959;
}

.download-table-xls-button {
  background-color: #21ba45;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  min-height: 1em;
  font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
  margin: 0 .25em 0 0;
  padding: .78571429em 1.5em .78571429em;
  font-weight: 700;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  border-radius: .28571429rem;
  text-shadow: none;
  display: inline-block;
  outline: 0;
  border: none;
  vertical-align: baseline;
  text-transform: none;
  text-shadow: none;
  border-radius: .28571429rem;
}

/******** Conversation ********/

.conversation-body {
  flex: 1;
  overflow-y: auto;
  background-color: #E8ECE7;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.conversation-body.massmessenger {
  background-color: #e8ece7;
  padding-bottom: 30px;
}

.conversation-item {
  display: flex;
  padding: 10px;
  justify-content: flex-end;
}

.conversation-item.inbound {
  flex-direction: row-reverse;
  padding-right: 30px
}

.conversation-item.outbound {
  padding-left: 30px
}

.conv-item-body-container {
  display: flex;
  flex-direction: column;
}

.conversation-item.inbound .conv-item-body-container {
  margin-left: 5px;
}

.conv-item-body-content {
  display: flex;
}

.conv-item-body-content.outbound {
  justify-content: flex-end;
}

.conv-item-body {
  max-width: 250px;
  position: relative;
  padding: 10px;
  border-radius: 25px;
}

.conv-item-body.large {
  max-width: 450px;
  font-size: 16px;
}

.conv-item-body.outbound {
  background-color: #73bd32;
  color: #fff;
  border-bottom-right-radius: 0px;
}

.conv-item-body.inbound {
  color: #333;
  background-color: #fff;
  border-bottom-left-radius: 0;
  border: 2px solid #73bd32;
}
/* Temp iMessage Styles */
.conv-item-body.outbound.imessage {
  background-color: #3377E3;
}

.conv-item-body.inbound.imessage {
  border: 2px solid #3377E3;
}

/* Temp Twitter styles */

.conv-item-body.outbound.twitter {
  background-color: #68b3f9;
}

.conv-item-body.inbound.twitter {
  border: 2px solid #68b3f9;
}

.conv-item-body.inbound.twitter_dm {
  border: 2px solid #68b3f9;
}

.conv-item-body.outbound.instagram {
  background-color: #FF5341;
}

.conv-item-body.inbound.instagram {
  border: 2px solid #FF5341;
}

.conv-item-body.inbound.instagram_dm {
  border: 2px solid #FF5341;
}

.conv-item-body.inbound.push_notify {
  border: 2px solid goldenrod;
}

.conv-item-body.inbound.voice {
  border: 2px solid #ccc;
}

/* end */

.conv-item-text {
  overflow-wrap: break-word;
  white-space: pre-line;
}

.conv-item.undelivered {
  color: #ffeb3b;
  font-size: 14px;
  margin-top: 5px;
}

.conv-item-time {
  display: flex;
  align-items: center;
  font-size: 11px;
  color: #555;
  margin-top: 2px;
}

.conv-item-time.outbound {
  justify-content: flex-end;
}

.conv-item-avatar {
  display: flex;
  align-items: flex-end;
}

/***** attachment/images *****/
.ci-attach-img {
  max-width: 100%;
  position: relative;
  box-shadow: 1px 1px 3px #333;
  border-radius: 20px;
  cursor: pointer;
  margin-top: 5px;
}

/***** input field placeholder *****/

::-webkit-input-placeholder {
  font-style: italic;
}
::-moz-placeholder {
  font-style: italic;
}
:-ms-input-placeholder {
  font-style: italic;
}
:-moz-placeholder {
  font-style: italic;
}

/***** LOADER *****/
#loading {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 20px;
  border: 3px solid rgba(255,255,255,.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s linear infinite;
  -webkit-animation: spin 1s linear infinite;
}

#loading.no-margin {
  margin: 0;
}

#loading.green {
  border: 3px solid #A0D346;
  border-top-color: #fff;
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}

/***** SCROLLBARS *****/

::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  /*border-radius: 10px;*/
  background-color: #A0D346;
}
::-webkit-scrollbar-track {
  /*border-radius: 10px;*/
  background-color: #D8D8D8;
}

/***** ReactTable Overrides *****/
.rt-th:focus {
  outline: none;
}

.auth-layout .ReactTable .rt-tbody .rt-td {
  border-right: none;
  display: table-cell;
  vertical-align: middle;
  margin: auto 0;
  text-align: center;
}

.auth-layout .ReactTable .rt-thead.-filters .rt-th {
  border-right: none;
  padding: 5px 5px;
  background-color: #ebebeb;
  overflow: visible;
}

/* .contacts-table .ReactTable .rt-tr.-odd {
  background: rgba(81, 154, 40, .05)
} */

.auth-layout .ReactTable {
  font-size: 15px;
  min-height: 0;
  min-width: 0;
  border-top: 1px solid #ccc;
  border-left: none;
}

.auth-layout .ReactTable .rt-td {
  padding: 5px 5px;
}


.auth-layout .ReactTable .rt-tbody .rt-tr-group {
  cursor: pointer;
  /* border-bottom: none; */
}

.auth-layout .ReactTable.no-pointer .rt-tbody .rt-tr-group {
  cursor: default;
}

.auth-layout .ReactTable .rt-thead,
.auth-layout .ReactTable .rt-tbody {
  cursor: default;
  /*overflow-y: scroll;
  overflow-x: hidden;*/
}

.auth-layout .ReactTable .-pagination input,
.auth-layout .ReactTable .-pagination select {
  padding: 0 3px;
}

.auth-layout .ReactTable .rt-thead .rt-th.-sort-asc,
.auth-layout .ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 5px 0 0 #A0D346;
}

.auth-layout .ReactTable .rt-thead .rt-th.-sort-desc,
.auth-layout .ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -5px 0 0 #A0D346;
}

.auth-layout .ReactTable .rt-thead.-header {
  box-shadow: 0 2px 0 0 rgba(0,0,0,0.15);
}

.auth-layout .ReactTable .rt-thead .rt-th,
.auth-layout .ReactTable .rt-thead .rt-td {
  padding: 10px 8px;
  border-right: none;
  text-transform: uppercase;
  text-align: left;
  position: inherit;
}

.auth-layout .ReactTable .rt-tbody .rt-td {
  text-align: left;
  padding: 5px 8px;
}
