.top-bar {
  background: #80c343;
  background: linear-gradient(180deg,#80c343 0,#519a28);
  border: none;
  height: 60px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  display: none;
}

.app-loader {
  height: 100vh;
}

.column-container > div.thin {
  width: 260px;
}

.column-container > div.double-wide {
  width: 700px;
}

.column-container > div.triple-wide {
  width: 1000px;
}

#context-menu {
  background-color: #fff;
  position: absolute;
}

#context-menu p {
  margin: 0;
  padding: 5px 10px;
  cursor: pointer;
}

#context-menu p:hover {
  background-color: #555;
  color: #FFF
}